const apiUrl = process.env.VUE_APP_API_URL;
const apiCertificateUrl = process.env.VUE_APP_API_CERTIFICATE;
const apiRegisterUrl = process.env.VUE_APP_API_REGISTER;
const apiLoginUrl = process.env.VUE_APP_API_LOGIN;
const apiResetUrl = process.env.VUE_APP_API_RESET;
const apiNewPasswordUrl = process.env.VUE_APP_API_NEW_PASSWORD;
const apiProgessUrl = process.env.VUE_APP_API_UPDATEPROGRESS;
const apiGetUser = process.env.VUE_APP_API_GETUSER;
const token = process.env.VUE_APP_SECRET;
const tokenEn = process.env.VUE_APP_SECRET_EN;

export default {
  fetchData(language) {
    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener("readystatechange", function() {
        if (this.readyState === 4) {
          resolve(JSON.parse(this.responseText));
        }
      });

      xhr.open("POST", `${apiUrl}`);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      xhr.setRequestHeader("Accept", "*/*");
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send();
    });
  },
  getUser(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiGetUser}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  generateCertificate(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiCertificateUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  register(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiRegisterUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  login(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiLoginUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  reset(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiResetUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  setNewPassword(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiNewPasswordUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  },
  updateProgress(language, form) {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${language === "AL" ? token : tokenEn}`
      );
      myHeaders.append("Accept", "*/*");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Headers", "*");
      myHeaders.append("cache-control", "no-cache");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(form),
        redirect: "follow"
      };

      fetch(`${apiProgessUrl}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let parsedData = JSON.parse(result);
          resolve(parsedData);
        })
        .catch(error => {
          console.log("error", error);
          reject(error);
        });
    });
  }
};
