<template>
  <div class="flexi-container page__internal page__unit-1" v-show="showPage">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <span v-text="'Поглавје 1'" class="sub-header__unit" />
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-2')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].firstUnit"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section - Добредојдовте на курсот -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/KxO_1634752319_video%201.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section - Информативен екосистем -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.internalPages[0].sections[1]"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--block"
          v-html="slotProps.inputData.description"
          data-aos="fade-down"
          :data-aos-duration="!isMobile ? 1500 : 1000"
        />
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
      </template>
    </flexi-wrapper>

    <!-- Third Section - Видови содржина -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[2]"
      :data-aos="!isMobile ? 'flip-down' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div
          v-if="!showPopUpSecondQuestion"
          class="hidden"
          v-view="popUpSecondQuestion"
        />
        <div
          class="flexi-wrapper__section--content--block"
          v-html="slotProps.inputData.description"
        />
        <div id="video2" class="video__wrapper" @click="togglePoster('video2')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/B5p_1634752319_video%202.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- ? Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[3]"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div
          v-if="!showQuizz"
          data-aos="fade-down"
          :data-aos-duration="!isMobile ? 1500 : 100"
          class="flexi-wrapper__section--content__block"
        >
          <div class="flexi-wrapper__section--content__block--subTitle">
            <h3 v-text="slotProps.inputData.subTitle" />
          </div>
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content__block--img"
          />
          <div
            v-text="slotProps.inputData.contentTitle"
            class="flexi-wrapper__section--content__block--contentTitle"
          />
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content__block--description"
          />
          <div class="flexi-wrapper__section--content__block--button">
            <flexi-button
              @button-clicked="
                startQuizz(inputData.internalPages[0].sections[3].string_id)
              "
              :text="slotProps.inputData.buttonText"
            />
          </div>
        </div>
        <flexi-quiz
          v-else
          :inputData="inputData.quizzes[0]"
          :contentAos="false"
          :changeSlideOnHeader="true"
          :resultOptions="{
            limit1: 3,
            limit2: 5
          }"
          :precent="precentQuizz"
          :language="language"
          data-aos="fade-up"
          :data-aos-duration="!isMobile ? 1500 : 1000"
        >
          <template v-slot:header="slotProps">
            <div
              v-if="!slotProps.showResult"
              class="flexi-quiz__header--content"
              data-aos="fade-right"
              :data-aos-duration="!isMobile ? 1500 : 1000"
            >
              <span v-text="'Прашање ' + slotProps.computedIndex" />
              <span v-text="'/'" />
              <span v-text="slotProps.inputData.questions.length" />
            </div>
            <div v-else />
          </template>
        </flexi-quiz>
      </template>
    </flexi-wrapper>

    <!-- Fourth Section - Именувај ги емоциите -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[4]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--paragraph">
          <p v-html="slotProps.inputData.description" />
          <p
            v-html="slotProps.inputData.widgets[0].description"
            class="middle"
            data-aos="fade-down"
            :data-aos-duration="!isMobile ? 1500 : 1000"
          />
          <p v-html="slotProps.inputData.widgets[0].paragraph" />
          <h3 v-text="slotProps.inputData.widgets[0].footerHeader" />
          <wheel-of-emotions />
          <!-- <p v-html="slotProps.inputData.widgets[0].paragraph" /> -->
        </div>
      </template>
    </flexi-wrapper>

    <!-- Fifth Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[6]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:header="slotProps">
        <div class="flexi-wrapper__section--header--title">
          <h3 v-text="slotProps.inputData.title" />
        </div>
        <div v-if="!showCategory">
          <div class="flexi-wrapper__section--header--section">
            <img
              :src="slotProps.inputData.img"
              :alt="inputData.internalPages[0].sections[6].title"
              class="flexi-wrapper__section--header--section--img"
              data-aos="fade-up"
              :data-aos-duration="!isMobile ? 1500 : 1000"
            />
            <p
              v-html="slotProps.inputData.description"
              class="flexi-wrapper__section--header--section--description"
              data-aos="fade-right"
              :data-aos-duration="!isMobile ? 1500 : 1000"
            />
            <flexi-button
              @button-clicked="startCategory"
              :text="slotProps.inputData.buttonText"
              class="flexi-wrapper__section--header--section--button"
            />
          </div>
        </div>
        <div
          v-else-if="showCategory && !showConsumptionResult"
          class="flexi-wrapper__section--header--section category"
        >
          <span
            v-if="language === 'AL'"
            v-text="'Избери категорија:'"
            class="flexi-wrapper__section--header--section--category--header"
          />
          <span
            v-else
            v-text="'Choose a category:'"
            class="flexi-wrapper__section--header--section--category--header"
          />
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            @click="selectActiveKey(key)"
            class="flexi-wrapper__section--header--section--category--item"
            :class="{ disabled: ifConsumptionKeyIsSaved(key) }"
          >
            <img
              :src="value.img"
              :alt="inputData.internalPages[0].sections[6].title"
              @click="selectActiveKey(key)"
              class="flexi-wrapper__section--header--section--category--img"
            />
            <span
              v-text="value.title"
              @click="selectActiveKey(key)"
              class="flexi-wrapper__section--header--section--category--title"
            />
          </div>
          <div
            v-if="!consumptionInProgress"
            class="
              flexi-wrapper__section--header--section--category--pre-result
            "
          >
            <span class="pre-result" v-text="consumptionResultPreText" />
            <flexi-button
              @button-clicked="showConsumptionLogResult"
              :text="'Прикажи резултат'"
            />
          </div>
        </div>
        <div
          v-else-if="showConsumptionResult"
          class="flexi-wrapper__section--header--section total-result"
        >
          <h2 v-text="'Резултат'" />
          <h3 v-text="consumptionResultFinalTitle" />
          <p v-text="consumptionResultFinalText" />
          <flexi-button
            @button-clicked="restartConsumptionLog"
            :text="'Рестартирај, започни одново'"
          />
        </div>
        <div
          v-if="
            itemKey === 0 ||
              itemKey === 1 ||
              itemKey === 2 ||
              itemKey === 3 ||
              itemKey === 4
          "
          class="flexi-wrapper__section--header--section choosed"
        >
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            v-show="itemKey === key"
            class="flexi-wrapper__section--header--section--category--item"
          >
            <span v-text="value.title" class="choosed--title" />
            <p v-html="value.paragraph" class="choosed--paragraph" />

            <div v-if="value.footerHeader" class="choosed--item">
              <p v-text="value.footerHeader" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>
            <div v-if="value.footerText" class="choosed--item">
              <p v-text="value.footerText" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value2"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value2"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>
            <div v-if="value.email" class="choosed--item">
              <p v-text="value.email" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value3"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value3"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>
            <div v-if="value.buttonText" class="choosed--item">
              <p v-text="value.buttonText" />

              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[key].value4"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[key].value4"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>

            <div v-if="itemKey === 2" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>

            <div v-if="itemKey === 3" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>

            <div v-if="itemKey === 4" class="choosed--item">
              <div class="choosed--item--range">
                <input
                  step="0.5"
                  type="range"
                  min="0"
                  max="5"
                  v-model="consumptionLog[itemKey].value1"
                  class="choosed--item--range--input"
                  :currval="consumptionLog[itemKey].value1"
                  oninput="this.nextElementSibling.value = this.value"
                />
                <output v-text="'0'" />
                <span v-text="' часа'" />
              </div>
            </div>
            <flexi-button
              v-if="consumptionInProgress"
              @button-clicked="saveConsumptionLogStep"
              :text="'Зачувај'"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Vocabullary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[0].sections[8]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[10].footerText" />
              <a
                v-text="slotProps.inputData.widgets[10].buttonLink"
                :href="slotProps.inputData.widgets[10].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.internalPages[0].sections[7]"
    >
      <template v-slot:content="slotProps">
        <flexi-button
          v-text="slotProps.inputData.buttonText"
          @button-clicked="goToRoute(slotProps.inputData.buttonLink)"
        />
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content" :class="additionalModalClass">
          <flexi-pop-up-question-multi
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[0]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
          <flexi-pop-up-question
            v-if="showPopUpSecondQuestion"
            :question="inputData.quizzes[3].questions[1]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlexiQuiz from "../../components/FlexiQuiz.vue";
import FlexiPopUpQuestion from "../../components/FlexiPopUpQuestion.vue";
import FlexiPopUpQuestionMulti from "../../components/FlexiPopUpQuestionMulti.vue";
import FlexiScrollingSlider from "../../components/FlexiScrollingSlider.vue";
import WheelOfEmotions from "../../components/WheelOfEmotions.vue";

export default {
  name: "unit-1",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 1. Медиумски простор",
      meta: [
        {
          name: "description",
          content:
            "Што подразбираме под „модерни медиуми“ и како тие функционираат? Идентификувајте ги вашите навики за консумација на медиумски содржини и видовите информации што ве интересираат. Научете како да разликувате факти од ставови и како да препознаете манипулативна содржина."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 1. Медиумски простор"
        },
        {
          name: "og:description",
          content:
            "Што подразбираме под „модерни медиуми“ и како тие функционираат? Идентификувајте ги вашите навики за консумација на медиумски содржини и видовите информации што ве интересираат. Научете како да разликувате факти од ставови и како да препознаете манипулативна содржина."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/h0A_1638412360_u1.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 1. Медиумски простор"
        },
        {
          name: "twitter:description",
          content:
            "Што подразбираме под „модерни медиуми“ и како тие функционираат? Идентификувајте ги вашите навики за консумација на медиумски содржини и видовите информации што ве интересираат. Научете како да разликувате факти од ставови и како да препознаете манипулативна содржина."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/h0A_1638412360_u1.png"
        }
      ]
    };
  },
  components: {
    FlexiQuiz,
    FlexiPopUpQuestion,
    FlexiPopUpQuestionMulti,
    FlexiScrollingSlider,
    WheelOfEmotions
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      answeredPopUpSecondQuestion: false,
      consumptionInProgress: true,
      consumptionLog: [
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        }
      ],
      consumptionResultPreText:
        "Супер! Ајде да ја сумираме вашата дневна интеракција со медиумите!",
      consumptionResultFinalTitle: null,
      consumptionResultFinalText: null,
      consumptionTotalHours: null,
      itemKey: false,
      openContent: false,
      precentQuizz: [
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "79%",
          y: "21%"
        },
        {
          x: "85%",
          y: "15%"
        },
        {
          x: "71%",
          y: "29%"
        },
        {
          x: "65%",
          y: "35%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "75%",
          y: "25%"
        },
        {
          x: "72%",
          y: "28%"
        },
        {
          x: "87%",
          y: "13%"
        },
        {
          x: "71%",
          y: "29%"
        },
        {
          x: "85%",
          y: "15%"
        }
      ],
      savedConsumptionKeys: [],
      showQuizz: false,
      showCategory: false,
      showConsumptionResult: false,
      showPopUpFirstQuestion: false,
      showPopUpSecondQuestion: false,
      sixthSectionWidgets: {
        options: {
          button: false,
          date: false,
          description: true,
          img: true,
          paragraphOn: false,
          subTitle: false,
          titleClickable: false
        }
      }
    };
  },
  methods: {
    addModalClass(boolean) {
      if (boolean) {
        this.additionalModalClass = "correct-modal-score";
      } else {
        this.additionalModalClass = "wrong-modal-score";
      }
    },
    calculateHours() {
      var result = [];

      for (let i = 0; i < this.consumptionLog.length; i++) {
        result.push(parseFloat(this.consumptionLog[i].value1));
        result.push(parseFloat(this.consumptionLog[i].value2));
        result.push(parseFloat(this.consumptionLog[i].value3));
        result.push(parseFloat(this.consumptionLog[i].value4));
      }

      this.consumptionTotalHours = result.reduce(function(acc, val) {
        return acc + val;
      }, 0);

      if (this.consumptionTotalHours < 2) {
        this.consumptionResultFinalTitle = "до 2 часа";
        this.consumptionResultFinalText =
          "Се чини дека не трошите премногу време на интеракција со медиумите. Не сте преоптоварени со информации и можете ефикасно да управувате со времето. Но, не е важна само количината на информации. Пронаоѓањето квалитетни информации и критичкото размислување се од суштинско значење. Имајте го ова предвид и научете повеќе во следните глави од курсот.";
      } else if (
        this.consumptionTotalHours >= 2 &&
        this.consumptionTotalHours <= 8
      ) {
        this.consumptionResultFinalTitle = "од 2 до 5 часа";
        this.consumptionResultFinalText =
          "Медиумите заземаат значаен дел од вашиот ден. Добивате голем број на информации. Не заборавајте да управувате со времето што го трошите во интеракција со медиумите. Но, не е важна само количината на информации. Пронаоѓањето квалитетни информации и критичкото размислување се од суштинско значење. Имајте го ова предвид и научете повеќе во следните поглавја од курсот.";
      } else {
        this.consumptionResultFinalTitle = "над 8 часа ";
        this.consumptionResultFinalText =
          "Хм! Поминувате МНОГУ време на медиумите. Можете ли да ги обработите сите тие информации, да го задржите фокусот и да бидете критични во исто време? Премногу информации може да ве прокупираат и да ви ја намалат способноста за критичко размислување. Не заборавајте да управувате со времето што го трошите на интеракција со медиумите. Но, не е важна само количината на информации. Пронаоѓањето квалитетни информации и критичкото размислување се од суштинско значење. Имајте го ова предвид и научете повеќе во понатамошните делови од курсот.";
      }
    },
    closeModal(data) {
      if (this.showPopUpFirstQuestion === true) {
        this.answeredPopUpFirstQuestion = true;
        location.hash = "#information-ecosystem";
        this.$router.push("/unit-1");
      } else {
        this.answeredPopUpSecondQuestion = true;
        location.hash = "#types-of-content";
        this.$router.push("/unit-1");
      }
      if (!this.score.scorePogress.unit1) {
        this.setSelectedAnswersPopUp(data);
      }
    },
    ifConsumptionKeyIsSaved(key) {
      return this.savedConsumptionKeys.includes(key);
    },
    saveConsumptionLogStep() {
      if (this.savedConsumptionKeys.length === 0) {
        this.savedConsumptionKeys.push(this.itemKey);
      } else if (!this.savedConsumptionKeys.includes(this.itemKey)) {
        this.savedConsumptionKeys.push(this.itemKey);

        if (this.savedConsumptionKeys.length === 5) {
          this.calculateHours();
          this.consumptionInProgress = false;
        }
      }
      this.smoothScroll("consumption-log");
      this.itemKey = false;
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit1) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpSecondQuestion = false;
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpSecondQuestion(e) {
      if (!this.score.scorePogress.unit1) {
        if (!this.answeredPopUpSecondQuestion) {
          if (e.percentTop > 0.608 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpSecondQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    restartConsumptionLog() {
      this.consumptionInProgress = true;
      this.consumptionLog = [
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        },
        {
          value1: "0",
          value2: "0",
          value3: "0",
          value4: "0"
        }
      ];

      this.consumptionTotalHours = null;
      this.consumptionResultFinalTitle = null;
      this.consumptionResultFinalText = null;
      this.itemKey = false;
      this.savedConsumptionKeys = [];
      this.showConsumptionResult = false;
    },
    selectActiveKey(key) {
      if (this.savedConsumptionKeys.length === 0) {
        this.itemKey = key;
      } else if (!this.savedConsumptionKeys.includes(key)) {
        this.itemKey = key;
      }
    },
    startCategory() {
      this.smoothScroll("consumption-log");
      this.showCategory = true;
    },
    startQuizz(id) {
      this.smoothScroll(id);
      this.showQuizz = true;
    },
    showConsumptionLogResult() {
      this.showConsumptionResult = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].firstUnit[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      this.answeredPopUpSecondQuestion &&
      !this.score.scorePogress.unit1
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setLastUnit("/unit-2/part-a");
      this.setUnitScore("unit1");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
