<template>
  <div class="flexi-scrolling-slider__wrapper">
    <div
      class="flexi-scrolling-slider__wrapper--item"
      v-for="(section, index) in sections"
      :key="'section-' + index"
      :id="index + section.string_id"
      :class="{ active: index + section.string_id === index + activeSection }"
    >
      <img
        v-if="tooltipOn"
        :src="section.img"
        v-tooltip.left="section.title"
        :alt="section.title"
        @click="imageClicked(section.string_id)"
      />
      <img
        v-else
        :src="section.img"
        :alt="section.title"
        @click="imageClicked(section.string_id)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeSection: {
      type: String
    },
    sections: {
      type: Array
    },
    tooltipOn: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  methods: {
    imageClicked(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>
