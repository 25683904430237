import VeeValidate from "vee-validate";
const apiUrl = process.env.VUE_APP_API_UNIQUEMAIL;
const token = process.env.VUE_APP_SECRET;

export default {
  init() {
    VeeValidate.Validator.extend("uniqueEmail", {
      getMessage: () => "*Email tashmë në përdorim!!",
      validate: email =>
        new Promise(resolve => {
          var myHeaders = new Headers();

          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${token}`);
          myHeaders.append("Accept", "*/*");
          myHeaders.append("Access-Control-Allow-Origin", "*");
          myHeaders.append("Access-Control-Allow-Headers", "*");
          myHeaders.append("cache-control", "no-cache");

          var data = {
            email: email
          };

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: "follow"
          };

          fetch(`${apiUrl}`, requestOptions)
            .then(response => response.text())
            .then(result => {
              result = JSON.parse(result);
              return resolve({
                valid: !result
              });
            })
            .catch(error => {
              console.log("error", error);
            });
        })
    });
  }
};
