import client from "api-client";

export default {
  state: {
    score: {
      finalScore: 0,
      progress: 0,
      scorePoints: 10,
      last_unit: "",
      scorePogress: {
        unit1: false,
        unit2PartA: false,
        unit2PartB: false,
        unit3PartA: false,
        unit3PartB: false,
        unit4PartA: false,
        unit4PartB: false,
        unit4PartC: false,
        unit4PartD: false,
        unit5: false
      }
    },
    selectedAnswersPopUp: [],
    user: null
  },
  getters: {
    selectedAnswersPopUp: state => state.selectedAnswersPopUp,
    user: state => state.user,
    score: state => state.score
  },
  mutations: {
    restartScore(state) {
      state.score.finalScore = 0;
      state.score.progress = 0;
      state.score.last_unit = "";
      state.score.scorePogress = {
        unit1: false,
        unit2PartA: false,
        unit2PartB: false,
        unit3PartA: false,
        unit3PartB: false,
        unit4PartA: false,
        unit4PartB: false,
        unit4PartC: false,
        unit4PartD: false,
        unit5: false
      };
      state.selectedAnswersPopUp = [];
    },
    setLastUnit(state, unit) {
      state.score.last_unit = unit;
    },
    setScore(state, points) {
      state.score.progress = points;
    },
    setFinalScore(state, finalScore) {
      state.score.finalScore = finalScore;
    },
    setUnitScore(state, unit) {
      state.score.scorePogress[unit] = true;
    },
    setScoreProgress(state, object) {
      state.score.scorePogress = object;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSelectedAnswersPopUp(state, data) {
      state.selectedAnswersPopUp.push(data);
    },
    setSelectedAnswersPopUpArray(state, array) {
      state.selectedAnswersPopUp = array;
    }
  },
  actions: {
    getUser({ rootState, commit }, form) {
      return new Promise(resolve => {
        client.getUser(rootState.data.language, form).then(response => {
          commit("setUser", response.user);
          commit("setLastUnit", response.user.certificate.last_unit);
          commit("setScore", response.user.certificate.progress);
          commit("setFinalScore", response.user.certificate.total_score);
          commit("setScoreProgress", {});
          commit(
            "setScoreProgress",
            JSON.parse(response.user.certificate.scorePogress)
          );
          commit("setSelectedAnswersPopUpArray", []);
          commit(
            "setSelectedAnswersPopUpArray",
            JSON.parse(response.user.certificate.popUpQuestions)
          );
          resolve(response);
        });
      });
    },
    generateCertificate({ rootState, commit }, form) {
      return new Promise(resolve => {
        client
          .generateCertificate(rootState.data.language, form)
          .then(response => {
            commit("setUser", response.user);
            resolve(response);
          });
      });
    },
    restartScore({ commit }) {
      commit("restartScore");
    },
    register({ rootState, commit }, form) {
      return new Promise(resolve => {
        client.register(rootState.data.language, form).then(response => {
          commit("setUser", response.client);
          resolve(response);
        });
      });
    },
    login({ rootState, commit }, form) {
      return new Promise(resolve => {
        client.login(rootState.data.language, form).then(response => {
          if (response.user) {
            commit("setUser", response.user);

            if (response.user.certificate) {
              commit("setLastUnit", response.user.certificate.last_unit);
              commit("setScore", response.user.certificate.progress);
              commit("setFinalScore", response.user.certificate.total_score);
              commit("setScoreProgress", {});
              commit(
                "setScoreProgress",
                JSON.parse(response.user.certificate.scorePogress)
              );
              commit("setSelectedAnswersPopUpArray", []);
              commit(
                "setSelectedAnswersPopUpArray",
                JSON.parse(response.user.certificate.popUpQuestions)
              );
            }

            resolve(response);
          } else {
            resolve(false);
          }
        });
      });
    },
    reset({ rootState }, form) {
      return new Promise(resolve => {
        client.reset(rootState.data.language, form).then(response => {
          if (response.user) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    setNewPassword({ rootState }, form) {
      return new Promise(resolve => {
        client.setNewPassword(rootState.data.language, form).then(response => {
          if (response.user) {
            resolve(response);
          } else {
            resolve(false);
          }
        });
      });
    },
    setFinalScore({ commit }, score) {
      commit("setFinalScore", score);
    },
    setLastUnit({ commit }, unit) {
      commit("setLastUnit", unit);
    },
    setScore({ commit }, points) {
      commit("setScore", points);
    },
    setUnitScore({ commit }, unit) {
      commit("setUnitScore", unit);
    },
    setSelectedAnswersPopUp({ commit }, data) {
      commit("setSelectedAnswersPopUp", data);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    updateProgress({ rootState, commit }, form) {
      return new Promise(resolve => {
        client.updateProgress(rootState.data.language, form).then(response => {
          commit("setUser", response.user);
          resolve(response);
        });
      });
    }
  }
};
