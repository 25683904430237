export const unitsSections = [
  {
    firstUnit: [
      {
        title: "Добредојдовте на курсот",
        string_id: "welcome-to-the-course",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Информациски екосистем",
        string_id: "information-ecosystem",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png"
      },
      {
        title: "Видови содржина",
        string_id: "types-of-content",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "ФАКТИ наспроти МИСЛЕЊА",
        string_id: "facts-vs-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Именувај ги емоциите",
        string_id: "name-it-to-tame-it",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Дневник за консумација",
        string_id: "consumption-log",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      }
    ],
    secondUnitPartA: [
      {
        title: "Видови на медиуми",
        string_id: "types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Сопственост: Приватни и јавни медиуми",
        string_id: "private-non-private-media",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -550
      },
      {
        title: "Сопственост: Јавен радиодифузен сервис",
        string_id: "private-online-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Кој ги поседува медиумите во Северна Македонија?",
        string_id: "what-types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Независно новинарство",
        string_id: "state-media-in-Albania",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      }
    ],
    secondUnitPartB: [
      {
        title: "Како една сторија стигнува до нас?",
        string_id: "how-does-a-story",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Уредувачка политика",
        string_id: "editorial-policy",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Новинарски стандарди",
        string_id: "journalistic-standards",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Слобода на печат и цензура",
        string_id: "freedom-of-press",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      }
    ],
    thirdUnitPartA: [
      {
        title: "Социјалните медиуми како извор на вести",
        string_id: "social-media-as-a-newssource",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Персонализирани алгоритми и филтрирани содржини",
        string_id: "personalized-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Квиз: Алгоритми на социјалните мрежи",
        string_id: "quiz-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "ТЕД говор: Пазете се од филтрираните сфери на интернет",
        string_id: "ted-talk",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    thirdUnitPartB: [
      {
        title: "Когнитивни нарушувања",
        string_id: "cognitive-distortions",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Чести предрасуди",
        string_id: "common-biases",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartA: [
      {
        title:
          "Манипулативна содржина: погрешни информации дезинформации и злонамерни информации",
        string_id: "types-of-information-disorder",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Внимание! Xакерство",
        string_id: "attention-hacking",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabA: [
      {
        title: "Наслови и манипулативни фотографии",
        string_id: "tab-a-headlines",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -300
      },
      {
        title: "Наслови: Работи на кои треба да внимавате",
        string_id: "tab-a-headlines-things-to-look-out-for",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Фото манипулација - внимавајте!",
        string_id: "tab-a-manipulated-photos-watch-out",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Постојат три вида манипулации со фотографии",
        string_id: "tab-a-types-of-manipulation",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabB: [
      {
        title: "Ботови и тролови",
        string_id: "tab-b-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Трикови: Како да откриете ботови и тролови",
        string_id: "tab-b-life-hacks-how-to-detect-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -250
      }
    ],
    fourthUnitPartBTabC: [
      {
        title: "Лажно експертско мислење",
        string_id: "tab-c-fake-expert-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -300
      }
    ],
    fourthUnitPartBTabD: [
      {
        title: "Манипулација со оригиналниот извор",
        string_id: "tab-d-manipulation-with-the-original-source",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Манипулација на социјалните медиуми",
        string_id: "tab-d-manipulation-in-social-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -50
      }
    ],
    fourthUnitPartBTabE: [
      {
        title: "Алатки за проверка на факти",
        string_id: "tab-e-fact-checking-tools",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartC: [
      {
        title: "Говор на омраза наспроти слобода на говор",
        string_id: "hate-speach",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Како да пријавите говор на омраза на интернет",
        string_id: "how-to-report-hate-speech",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Квиз: Инклузивен јазик",
        string_id: "quizz-inclusive-language",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      }
    ],
    fourthUnitPartD: [
      {
        title: "Што е синдром на злобен свет?",
        string_id: "what-is-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Студија на случај:",
        string_id: "the-fear-instinct",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Дали ги контролираме нашите одлуки?",
        string_id: "what-should-we-do-to-overcome-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      }
    ],
    fifthUnit: [
      {
        title: "Пет совети како да се заштитите од дезинформации",
        string_id: "five-tips-to-protect-yourself",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Имајте предвид!",
        string_id: "keep-in-mind",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      }
    ]
  }
];
