<template>
  <div
    class="flexi-container page__internal page__unit-4-part-c"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderFourthUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-4/part-b')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-4/part-d')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].fourthUnitPartC"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/JkF_1634752321_video%2010.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].sections[1]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Quizz Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].sections[5]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div v-if="!showQuizz" class="flexi-wrapper__section--content__block">
          <div class="flexi-wrapper__section--content__block--subTitle">
            <h3 v-text="slotProps.inputData.subTitle" />
          </div>
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content__block--img"
          />
          <div
            v-text="slotProps.inputData.contentTitle"
            class="flexi-wrapper__section--content__block--contentTitle"
          />
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content__block--description"
          />
          <div class="flexi-wrapper__section--content__block--button">
            <flexi-button
              @button-clicked="
                startQuizz(inputData.internalPages[11].sections[5].string_id)
              "
              :text="slotProps.inputData.buttonText"
            />
          </div>
        </div>
        <flexi-quiz
          v-else
          :inputData="inputData.quizzes[2]"
          :contentAos="false"
          :changeSlideOnHeader="true"
          :precent="precentQuizz"
          :resultOptions="{
            limit1: 3,
            limit2: 5
          }"
          :language="language"
        >
          <template v-slot:header="slotProps">
            <div
              v-if="!slotProps.showResult"
              class="flexi-quiz__header--content"
            >
              <span v-text="slotProps.computedIndex" />
              <span v-text="'/'" />
              <span v-text="slotProps.inputData.questions.length" />
            </div>
            <div v-else />
          </template>
        </flexi-quiz>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].sections[2]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[18].footerText" />
              <a
                v-text="slotProps.inputData.widgets[18].buttonLink"
                :href="slotProps.inputData.widgets[18].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[11].sections[3]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[11]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderFourthUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiQuiz from "../../../components/FlexiQuiz.vue";

export default {
  name: "part-c",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 4. Дезинформации и манипулација",
      meta: [
        {
          name: "description",
          content:
            "Што е говор на омраза и од каде доаѓа? Како може стереотипите да доведат до насилство и омраза? Дознајте како да пријавите говор на омраза на социјалните медиуми."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "og:description",
          content:
            "Што е говор на омраза и од каде доаѓа? Како може стереотипите да доведат до насилство и омраза? Дознајте како да пријавите говор на омраза на социјалните медиуми."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/7Kr_1638413035_u4c.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "twitter:description",
          content:
            "Што е говор на омраза и од каде доаѓа? Како може стереотипите да доведат до насилство и омраза? Дознајте како да пријавите говор на омраза на социјалните медиуми."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/7Kr_1638413035_u4c.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiScrollingSlider,
    FlexiQuiz
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      showQuizz: false,
      subHeaderKey: 2,
      subHeaderFourthUnit: subHeaderFourthUnit,
      thirdSection: {
        options: {
          subTitle: true,
          paragraph: true,
          img: false,
          button: false
        }
      },
      quizPrecent: [
        {
          x: "96%",
          y: "4%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "98%",
          y: "2%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "90%",
          y: "10%"
        },
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "93%",
          y: "7%"
        },
        {
          x: "88%",
          y: "12%"
        },
        {
          x: "92%",
          y: "8%"
        },
        {
          x: "80%",
          y: "20%"
        }
      ]
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit4PartC) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#hate-speach";
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit4PartC) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    startQuizz(id) {
      this.smoothScroll(id);
      this.showQuizz = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fourthUnitPartC[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit4PartC
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit4PartC");
      this.setLastUnit("/unit-4/part-d");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
