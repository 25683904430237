export const subHeaderSecondUnit = [
  {
    name: "Сопственост",
    href: "/unit-2/part-a",
    isPage: false
  },
  {
    name: "Агенда сетинг",
    href: "/unit-2/part-b",
    isPage: false
  }
];

export const subHeaderThirdUnit = [
  {
    name: "Како функционираат социјалните медиуми",
    href: "/unit-3/part-a",
    isPage: false
  },
  {
    name: "Когнитивни нарушувања",
    href: "/unit-3/part-b",
    isPage: false
  }
];

export const subHeaderFourthUnit = [
  {
    name: "Што се дезинформации?",
    href: "/unit-4/part-a",
    isPage: false
  },
  {
    name: "Манипулација",
    href: "/unit-4/part-b",
    isPage: false
  },
  {
    name: "Стереотипи и говор на омраза",
    href: "/unit-4/part-c",
    isPage: false
  },
  {
    name: "Синдром на злобен свет",
    href: "/unit-4/part-d",
    isPage: false
  }
];
