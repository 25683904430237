import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import data from "./modules/data";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    data,
    user
  },
  plugins: [
    createPersistedState({
      paths: ["user", "score"]
    })
  ]
});
