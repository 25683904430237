import client from "api-client";

export default {
  state: {
    inputData: {},
    year: null,
    loadingScreen: true,
    theme: "darkTheme",
    language: "AL",
    isMobile: false
  },
  getters: {
    inputData: state => state.inputData,
    isMobile: state => state.isMobile,
    language: state => state.language,
    loadingScreen: state => state.loadingScreen,
    theme: state => state.theme,
    year: state => state.year
  },
  mutations: {
    toggleTheme: state => {
      state.theme === "darkMode"
        ? (state.theme = "")
        : (state.theme = "darkMode");
      document.documentElement.setAttribute("data-theme", state.theme);
    },
    setData(state, data) {
      state.inputData = data;
      state.loadingScreen = false;
    },
    setYear(state) {
      state.year = new Date().getFullYear();
    },
    setTheme: state => {
      document.documentElement.setAttribute("data-theme", state.theme);
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setMobile(state, isMobile) {
      state.isMobile = isMobile;
    }
  },
  actions: {
    fetchData({ state, commit }) {
      return client.fetchData(state.language).then(data => {
        commit("setData", data);
      });
    },
    setMobile({ commit }, isMobile) {
      commit("setMobile", isMobile);
    },
    setYear({ commit }) {
      commit("setYear");
    },
    setTheme({ commit }) {
      commit("setTheme");
    },
    setLanguage({ commit }, language) {
      client.fetchData(language).then(data => {
        commit("setData", data);
      });
      commit("setLanguage", language);
    },

    toggleTheme({ commit }) {
      commit("toggleTheme");
    }
  }
};
