import Vue from "vue";
import VeeValidate from "vee-validate";
import certificateValidator from "./uniqueCertificate";
import emailValidator from "./uniqueEMail";

Vue.use(VeeValidate);
certificateValidator.init();
emailValidator.init();

VeeValidate.Validator.localize({
  en: {
    messages: {
      required: () => "*Полето е задолжително",
      email: () => "*Е-поштата мора да биде валидна",
      min: () => "*Полето мора да содржи најмалку 1 буква"
    }
  }
});
