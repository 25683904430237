import Vue from "vue";
import { gsap, TimelineMax, Power2 } from "gsap";
import { mapGetters, mapActions } from "vuex";
import { unitsSections } from "../static/data/sections";

gsap.registerPlugin(TimelineMax, Power2);

Vue.mixin({
  data() {
    return {
      currentModalId: null,
      isNavigationOpen: false,
      loading: false,
      modal: null,
      modalArrayData: [],
      showPage: false,
      unitsSections: unitsSections
    };
  },
  computed: {
    ...mapGetters(["isMobile", "score", "user", "selectedAnswersPopUp"])
  },
  methods: {
    ...mapActions([
      "setLastUnit",
      "setScore",
      "setUnitScore",
      "setSelectedAnswersPopUp",
      "updateProgress"
    ]),
    animateTopCard() {
      const tl = new TimelineMax();
      tl.fromTo(
        ".flexi-header__wrapper",
        {
          duration: 1.1,
          y: "-100%",
          ease: "bounce",
          opacity: 0
        },
        {
          duration: 1.1,
          y: 0,
          ease: "bounce",
          opacity: 1
        }
      )
        .fromTo(
          ".flexi-desktop-navigation__wrapper--item",
          {
            duration: 0.2,
            opacity: 0,
            delay: 1,
            stagger: 0.5
          },
          {
            duration: 0.2,
            opacity: 1,
            delay: 1,
            stagger: 0.5
          },
          "-=1.55"
        )
        .fromTo(
          ".flexi-container",
          {
            opacity: 0
          },
          {
            opacity: 1
          },
          "-=0.35"
        )
        .fromTo(
          ".flexi-top-card__wrapper",
          {
            duration: 1,
            y: "-100%",
            ease: "bounce"
          },
          {
            duration: 1,
            y: 0,
            ease: "rough"
          },
          "-=2.35"
        )
        .fromTo(
          ".score__wrapper",
          {
            duration: 1,
            opacity: 0
          },
          {
            duration: 1,
            opacity: 1
          },
          "-=0.75"
        )
        .fromTo(
          ".flexi-top-card__wrapper--media",
          {
            duration: 1,
            y: "-100%",
            ease: "bounce"
          },
          {
            duration: 1,
            y: 0,
            ease: "bounce"
          },
          "-=0.1"
        )
        .fromTo(
          ".flexi-top-card__wrapper--content",
          {
            duration: 1,
            y: "-50%",
            opacity: 0
          },
          {
            duration: 1,
            y: 0,
            opacity: 1
          },
          "-=1"
        )
        .fromTo(
          ".flexi-btn",
          {
            duration: 1,
            x: "-50%",
            opacity: 0
          },
          {
            duration: 1,
            x: 0,
            opacity: 1
          },
          "-=2.3"
        )

        .fromTo(
          ".footer__wrapper",
          {
            opacity: 0
          },
          {
            opacity: 1
          }
        );
    },
    openModal(data, array, id, index) {
      var self = this;
      self.modalArrayData = array;
      self.modal = data;
      self.currentModalId = index;
      if (data === "#login") {
        location.href = data;
      } else {
        location.href = "#register";
      }
    },
    openPopUpModal() {
      location.href = "#pop-up-question";
    },
    goToMobileRoute(route) {
      this.isNavigationOpen = false;
      this.$router.push(route);
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    goToRouteFromObject(data) {
      this.$router.push(data.buttonLink);
    },
    goToExternalPage(url) {
      window.open(url, "_blank").focus();
    },
    openVocabulary() {
      if (this.openContent === false) {
        this.openContent = true;
      } else {
        this.openContent = false;
      }
    },
    smoothScroll(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    toggleBurgerMenu() {
      this.isNavigationOpen = !this.isNavigationOpen;
    },
    togglePoster(video) {
      let id = document.getElementById(video);
      let img = id.childNodes[1];
      img.style.display = "none";
    },
    expandedAos(key) {
      if (!this.isMobile) {
        if (key === 0) {
          return "fade-right";
        } else if (key === 1) {
          return "fade-down";
        } else if (key === 2) {
          return "fade-up";
        } else {
          return "fade-left";
        }
      } else {
        return "";
      }
    },
    expandedAosZoom(key) {
      if (!this.isMobile) {
        if (key === 0) {
          return "zoom-in-right";
        } else if (key === 1) {
          return "zoom-in-up";
        } else if (key === 2) {
          return "zoom-in-left";
        } else if (key === 3) {
          return "zoom-in-right";
        } else if (key === 4) {
          return "zoom-in-down";
        } else if (key === 5) {
          return "zoom-out-left";
        } else {
          return "zoom-in-right";
        }
      } else {
        return "";
      }
    }
  }
});
