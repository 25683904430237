<template>
  <div id="flexi-quizz" class="flexi-quiz">
    <div class="flexi-quiz__header">
      <slot name="header" v-bind="{ computedIndex, inputData, showResult }">
        <div
          class="flexi-quiz__header--item"
          v-for="(value, key) in inputData.questions"
          :key="key"
          @click="changeSlide(key)"
          :data-aos="contentAos ? contentAosType : ''"
          :data-aos-duration="contentAosDuration"
        >
          <div
            class="square"
            :class="{
              'active-square': currentIndex === key,
              'passed-square': answerCollection.length > key
            }"
          >
            <div v-if="turnedCalculation">
              <div v-if="!showResult">
                <span
                  class="number"
                  v-if="answerCollection.length < key + 1"
                  v-text="key + 1"
                />
                <span class="checked" v-else> &#63; </span>
              </div>
              <div v-else>
                <span class="checked" v-if="!answerCollection[key]">
                  &#10006;
                </span>
                <span class="checked" v-else> &#10003; </span>
              </div>
            </div>
            <div v-else>
              <div>
                <span class="checked" v-if="!answerCollection[key]">
                  &#10006;
                </span>
                <span class="checked" v-else> &#10003; </span>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div
      class="flexi-quiz__content"
      :class="{ 'content-warning': showWarning }"
      v-for="key in [currentIndex]"
      :key="key"
    >
      <div v-if="!showResult">
        <div class="flexi-quiz__content--question">
          <span
            class="question"
            v-text="currentItem.question"
            :data-aos="contentAos ? contentAosType : ''"
            :data-aos-duration="contentAosDuration"
          />
          <img
            class="image"
            :src="
              currentItem.img
                ? currentItem.img
                : 'https://app.imgforce.com/images/user/gEu_1628691934_350x150.png'
            "
          />
        </div>
        <div
          class="flexi-quiz__content--answer"
          v-for="(value, key) in currentItem.answers"
          :key="key"
          :data-aos="contentAos ? contentAosType : ''"
          :data-aos-duration="contentAosDuration"
        >
          <div
            v-if="turnedCalculation"
            class="answer"
            :class="{
              'wrong-answer':
                selectedAnswer && !value.correct && !checkCorrectAnswer(),
              'correct-answer': selectedAnswer && value.correct,
              'selected-answer': selectedAnswer && value.id === selectedAnswerId
            }"
            @click="selectAnswer(value, value.id, value.correct)"
          >
            <div class="circle" />
            <span class="text" v-text="value.answer" />
          </div>
          <div
            v-if="turnedCalculation && selectedAnswer"
            class="answer icons"
            :class="{
              'wrong-answer':
                selectedAnswer && !value.correct && !checkCorrectAnswer(),
              'correct-answer': selectedAnswer && value.correct,
              'selected-answer': selectedAnswer && value.id === selectedAnswerId
            }"
            @click="selectAnswer(value, value.id, value.correct)"
          >
            <font-awesome-icon class="icon" icon="user" size="1x" />
            <span
              v-if="value.correct"
              class="text"
              v-text="precent[currentIndex].x"
            />
            <span
              v-if="!value.correct"
              class="text"
              v-text="precent[currentIndex].y"
            />
          </div>
          <span
            v-if="currentItem.suggestion && value.id === selectedAnswerId"
            class="suggestion"
            :class="{
              'wrong-suggestion':
                selectedAnswer && !value.correct && !checkCorrectAnswer(),
              'correct-suggestion': selectedAnswer && value.correct
            }"
            v-html="currentItem.suggestion"
          />
        </div>
        <transition :name="transitionWarning" mode="out-in">
          <div
            v-if="showWarning"
            key="content1"
            class="flexi-quiz__content--warning"
          >
            <span
              class="warning"
              v-text="
                language === 'MK'
                  ? '*Изберете еден од понудените одговори'
                  : '*Zgjidhni një nga përgjigjet e ofruara'
              "
            />
          </div>
        </transition>
      </div>
      <div
        v-else
        :data-aos="contentAos ? contentAosType : ''"
        :data-aos-duration="contentAosDuration"
      >
        <div class="flexi-quiz__content--result">
          <span
            class="correct-answers"
            v-if="correctAnswersType === 'normal'"
            v-text="
              language == 'MK'
                ? 'Имате ' + correctAnswers + ' точни одговори'
                : correctAnswers + '/' + inputData.questions.length
            "
          />
          <span class="correct-answers" v-else>
            <span
              v-if="language === 'MK'"
              v-text="
                'Вие одговоривте ' +
                  correctAnswers +
                  ' прашања точно од вкупно ' +
                  answerCollection.length +
                  ' прашања на темата.'
              "
            />
            <span
              v-else
              v-text="
                'Ти одговараш ' +
                  correctAnswers +
                  ' прашања точно од вкупниот број ' +
                  answerCollection.length +
                  ' прашања на темата.'
              "
            >
            </span>
          </span>
          <span class="result" v-html="computedResult" />
        </div>
      </div>
    </div>
    <flexi-pagination
      :currentIndex="currentIndex"
      :widgets="inputData.questions"
    >
      <template v-slot:next-button="slotProps">
        <a v-if="!showResult" class="flexi-pagination__next" @click="next">
          <div class="pagination-button pagination-button--rotated">
            <div
              class="pagination-button__svg"
              :class="{
                disabled: slotProps.disabledNext,
                blink: selectedAnswer
              }"
            />
          </div>
        </a>
        <a v-else class="flexi-pagination__next restart" @click="restart">
          <div class="pagination-button pagination-button--rotated">
            <div class="pagination-button__svg" />
          </div>
        </a>
      </template>
    </flexi-pagination>
  </div>
</template>

<script>
export default {
  name: "flexi-quiz",
  props: {
    inputData: {
      type: Object
    },
    changeSlideOnHeader: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    paginationBackButton: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    precent: {
      type: Array,
      default: () => {
        return [
          {
            x: "89%",
            y: "11%"
          },
          {
            x: "79%",
            y: "21%"
          },
          {
            x: "80%",
            y: "20%"
          },
          {
            x: "29%",
            y: "71%"
          },
          {
            x: "60%",
            y: "40%"
          },
          {
            x: "95%",
            y: "5%"
          },
          {
            x: "74%",
            y: "26%"
          },
          {
            x: "70%",
            y: "30%"
          },
          {
            x: "72%",
            y: "28%"
          },
          {
            x: "77%",
            y: "23%"
          }
        ];
      }
    },
    resultOptions: {
      type: Object,
      default: () => {
        return {
          limit1: 4,
          limit2: 9
        };
      }
    },
    transitionWarning: {
      type: String,
      default: () => {
        return "slider";
      }
    },
    contentAos: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    contentAosType: {
      type: String,
      default: () => {
        return "fade-right";
      }
    },
    contentAosDuration: {
      type: String,
      default: () => {
        return "1500";
      }
    },
    correctAnswersType: {
      type: String,
      default: () => {
        return "normal";
      }
    },
    language: {
      type: String,
      default: () => {
        return "MK";
      }
    }
  },
  data() {
    return {
      currentIndex: 0,
      selectedAnswer: false,
      selectedAnswerId: null,
      selectedAnswerCorrect: null,
      selectedAnswerObject: null,
      showResult: false,
      showWarning: false,
      turnedCalculation: true,
      answerCollection: [],
      answerObjectCollection: [],
      computedResult: null,
      correctAnswers: null
    };
  },
  methods: {
    back() {
      if (this.paginationBackButton) {
        this.currentIndex -= 1;
      }
    },
    checkCorrectAnswer() {
      if (this.selectedAnswerObject) {
        return this.selectedAnswerObject.correct;
      } else {
        return false;
      }
    },
    calculateResult() {
      let result = this.answerCollection.filter(value => {
        return value === 1;
      }).length;
      this.correctAnswers = result;
      if (result <= this.resultOptions.limit1) {
        this.computedResult = this.inputData.result1;
      } else if (
        result > this.resultOptions.limit1 &&
        result <= this.resultOptions.limit2
      ) {
        this.computedResult = this.inputData.result2;
      } else if (result > this.resultOptions.limit2) {
        this.computedResult = this.inputData.result3;
      }
    },
    changeSlide(index) {
      if (
        this.changeSlideOnHeader &&
        !this.showResult &&
        this.turnedCalculation
      ) {
        if (this.currentIndex < this.inputData.questions.length - 1) {
          if (this.selectedAnswer) {
            this.currentIndex += 1;
            this.answerCollection.push(this.selectedAnswerCorrect);
            this.answerObjectCollection.push(this.selectedAnswerObject);
            this.selectedAnswer = false;
            this.selectedAnswerId = null;
            this.selectedAnswerCorrect = null;
            this.selectedAnswerObject = null;
            this.showWarning = false;
          } else {
            this.showWarning = true;
          }
        } else {
          if (this.selectedAnswer) {
            this.showResult = true;
            this.answerCollection.push(this.selectedAnswerCorrect);
            this.answerObjectCollection.push(this.selectedAnswerObject);
            this.selectedAnswer = false;
            this.selectedAnswerId = null;
            this.selectedAnswerCorrect = null;
            this.selectedAnswerObject = null;
            this.showWarning = false;
            this.calculateResult();
          } else {
            this.showWarning = true;
          }
        }
      } else if (this.showResult) {
        this.currentIndex = index;
        this.showResult = false;
        this.turnedCalculation = false;
      } else if (!this.turnedCalculation) {
        this.currentIndex = index;
      }
    },
    next() {
      if (this.turnedCalculation) {
        if (this.currentIndex < this.inputData.questions.length - 1) {
          if (this.selectedAnswer) {
            this.currentIndex += 1;
            this.answerCollection.push(this.selectedAnswerCorrect);
            this.answerObjectCollection.push(this.selectedAnswerObject);
            this.selectedAnswer = false;
            this.selectedAnswerId = null;
            this.selectedAnswerCorrect = null;
            this.selectedAnswerObject = null;
            this.showWarning = false;
          } else {
            this.showWarning = true;
          }
        } else {
          if (this.selectedAnswer) {
            this.showResult = true;
            this.answerCollection.push(this.selectedAnswerCorrect);
            this.answerObjectCollection.push(this.selectedAnswerObject);
            this.selectedAnswer = false;
            this.selectedAnswerId = null;
            this.selectedAnswerCorrect = null;
            this.selectedAnswerObject = null;
            this.showWarning = false;
            this.calculateResult();
          } else {
            this.showWarning = true;
          }
        }
      }
    },
    selectAnswer(answer, id, correct) {
      if (!this.selectedAnswer) {
        if (this.turnedCalculation) {
          this.selectedAnswer = true;
          this.selectedAnswerObject = answer;
          this.selectedAnswerId = id;
          this.selectedAnswerCorrect = correct;
          this.showWarning = false;
        }
      }
    },
    restart() {
      this.currentIndex = 0;
      this.selectedAnswer = false;
      this.selectedAnswerId = null;
      this.selectedAnswerCorrect = null;
      this.selectedAnswerObject = null;
      this.showResult = false;
      this.showWarning = false;
      this.turnedCalculation = true;
      this.answerCollection = [];
      this.answerObjectCollection = [];
      this.computedResult = null;
      this.correctAnswers = null;
    }
  },
  computed: {
    currentItem() {
      return this.inputData.questions[
        Math.abs(this.currentIndex) % this.inputData.questions.length
      ];
    },
    computedIndex() {
      if (this.currentItem) {
        var result = "";
        this.inputData.questions.filter((elem, index) => {
          if (elem.id === this.currentItem.id) {
            result = index;
          }
        });
        return result + 1;
      } else {
        return null;
      }
    }
  }
};
</script>
