<template>
  <div class="flexi-container page__internal page__unit-4" v-show="showPage">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[8].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <span v-text="'Поглавје 4'" class="sub-header__unit" />
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-3/part-b')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-4/part-a')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[8].sections[0]"
    >
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :options="firstSection.options"
          :inputData="value"
          @button-clicked="goToRouteFromObject"
          :data-aos="expandedAosZoom(key)"
          :data-aos-duration="!isMobile ? 1500 : 1000"
          data-aos-easing="ease-in"
        >
        </flexi-column>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "unit-4",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 4. Дезинформации и манипулација",
      meta: [
        {
          name: "description",
          content:
            "Научете како да разликувате различни видови манипулации и дезинформации на традиционалните, онлајн и социјалните медиуми: лажни вести, дезинформации, ботови, тролови и многу повеќе."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "og:description",
          content:
            "Научете како да разликувате различни видови манипулации и дезинформации на традиционалните, онлајн и социјалните медиуми: лажни вести, дезинформации, ботови, тролови и многу повеќе."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/wgb_1638412831_u4.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "twitter:description",
          content:
            "Научете како да разликувате различни видови манипулации и дезинформации на традиционалните, онлајн и социјалните медиуми: лажни вести, дезинформации, ботови, тролови и многу повеќе."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/wgb_1638412831_u4.png"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  data() {
    return {
      firstSection: {
        options: {
          button: false,
          date: false,
          description: false,
          img: true,
          paragraphOn: true,
          subTitle: false,
          titleClickable: true
        }
      }
    };
  },
  methods: {},
  mounted() {
    this.showPage = true;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  }
};
</script>
