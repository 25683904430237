<template>
  <div class="flexi-container page__home" v-show="showPage">
    <div class="password-section">
      <div class="left">
        <img
          src="https://app.imgforce.com/images/user/xS2_1637269795_01x500.gif"
        />
      </div>
      <div class="right">
        <div class="title">Постави нова лозинка</div>
        <form
          novalidate
          data-vv-scope="password-form"
          id="password-form"
          @submit.prevent="setNewClientPassword"
        >
          <div class="modal-input">
            <input
              @paste.prevent
              v-model="form.email"
              v-validate="{
                required: true
              }"
              :class="{ 'valid-input': errors.has('password-form.Email') }"
              class="form-field__wrapper--input disabled"
              type="text"
              name="Email"
              placeholder="Унесите емаил"
              required
              disabled
            />
            <validation-message
              :message="errors.first('password-form.Email')"
              :show="errors.has('password-form.Email')"
            />
          </div>
          <div class="modal-input">
            <input
              @paste.prevent
              v-model="form.password"
              v-validate="{
                required: true,
                min: 4,
                max: 100
              }"
              :class="{
                'valid-input': errors.has('password-form.Password')
              }"
              class="form-field__wrapper--input"
              type="password"
              name="Password"
              placeholder="Лозинка"
              required
            />
            <validation-message
              :message="errors.first('password-form.Password')"
              :show="errors.has('password-form.Password')"
            />
          </div>
          <button class="flexi-btn">
            <span v-if="!processing">Постави нова лозинка</span>
            <spring-spinner
              class="spinner"
              v-else
              :animation-duration="2000"
              :size="25"
              color="#fc5000"
            />
          </button>
        </form>
        <div class="invalid-form warning-login__wrapper" v-if="showWarning">
          <span
            class="warning-login__wrapper--item"
            v-text="'*Adresa e-pošte je već resetovana!'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        email: null,
        password: null,
        token: null
      },
      processing: false,
      showWarning: false
    };
  },
  computed: {
    ...mapGetters(["inputData", "language", "user", "isMobile"])
  },
  methods: {
    ...mapActions(["setNewPassword"]),
    setNewClientPassword() {
      this.$validator.validateAll("password-form").then(result => {
        if (result) {
          this.processing = true;
          this.setNewPassword(this.form).then(result => {
            if (result) {
              this.processing = false;
              this.showWarning = false;
              this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                  this.$router.replace("/");
                });
              });
            } else {
              this.showWarning = true;
              this.processing = false;
            }
          });
        } else {
          this.processing = false;
        }
      });
    }
  },
  mounted() {
    this.showPage = true;
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.params.token
      ? this.$route.params.token
      : this.$route.path.substr(this.$route.path.lastIndexOf("/") + 1);
  }
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
