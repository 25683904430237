<template>
  <div
    class="flexi-container page__internal page__unit-4-part-d"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderFourthUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-4/part-c')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-5')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].fourthUnitPartD"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--content-title">
          <p v-html="slotProps.inputData.contentTitle" />
        </div>
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/9w6_1634752329_video%2011.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!--Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].sections[1]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].sections[3]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div id="video2" class="video__wrapper" @click="togglePoster('video2')">
          <vue-plyr v-if="slotProps.inputData.buttonLink">
            <div
              data-plyr-provider="youtube"
              :data-plyr-embed-id="slotProps.inputData.buttonLink"
              class="flexi-wrapper__section--content--plyr"
            />
          </vue-plyr>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Vocabulary section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].sections[4]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[12].footerText" />
              <a
                v-text="slotProps.inputData.widgets[12].buttonLink"
                :href="slotProps.inputData.widgets[12].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[12].sections[2]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderFourthUnit } from "../../../static/data/subHeaders";

import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";

export default {
  name: "part-d",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 4. Дезинформации и манипулација",
      meta: [
        {
          name: "description",
          content:
            "Дали некогаш сте помислиле дека светот е опасен и страшен по гледањето вести? Дознајте како негативните вести влијаат врз нашата перцепција на светот."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "og:description",
          content:
            "Дали некогаш сте помислиле дека светот е опасен и страшен по гледањето вести? Дознајте како негативните вести влијаат врз нашата перцепција на светот."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/hB3_1638413105_u4d.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 4. Дезинформации и манипулација"
        },
        {
          name: "twitter:description",
          content:
            "Дали некогаш сте помислиле дека светот е опасен и страшен по гледањето вести? Дознајте како негативните вести влијаат врз нашата перцепција на светот."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/hB3_1638413105_u4d.png"
        }
      ]
    };
  },
  components: {
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      fifthSection: {
        options: {
          subTitle: true,
          paragraph: true,
          img: false,
          button: false
        }
      },
      openContent: false,
      subHeaderFourthUnit: subHeaderFourthUnit,
      subHeaderKey: 3
    };
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fourthUnitPartD[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (!this.score.scorePogress.unit4PartD) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit4PartD");
      this.setLastUnit("/unit-5");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
