<template>
  <div
    class="flexi-container page__internal page__unit-2-part-a"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderSecondUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-2')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-2/part-b')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].secondUnitPartA"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[0]"
      :data-aos="'fade-down'"
      :data-aos-duration="!isMobile ? 1200 : 1000"
      data-aos-easing="ease-in"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :class="{ active: itemKey === key }"
          @click="selectActiveClass(key)"
          class="flexi-wrapper__section--content--item"
        >
          <div class="parent">
            <p v-text="value.title" class="item--title" />
            <img :src="value.img" :alt="value.title" class="item--img" />
          </div>
        </div>
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          v-show="itemKey === key"
        >
          <div class="choosed">
            <p v-html="value.paragraph" />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Ownership: Private and non-private Media -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[7]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div id="video3" class="video__wrapper" @click="togglePoster('video3')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/Yik_1634752320_video%203.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[1]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p
            v-html="slotProps.inputData.description"
            data-aos="fade-down"
            :data-aos-duration="!isMobile ? 1500 : 100"
          />
        </div>
        <div id="video4" class="video__wrapper" @click="togglePoster('video4')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="flexi-wrapper__section--content--paragraph"
        >
          <p
            v-html="value.paragraph"
            data-aos="fade-down"
            :data-aos-duration="!isMobile ? 1500 : 100"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!--Quizz Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[8]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div v-if="!showQuizz" class="flexi-wrapper__section--content__block">
          <div class="flexi-wrapper__section--content__block--subTitle">
            <h3 v-text="slotProps.inputData.subTitle" />
          </div>
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content__block--img"
          />
          <div
            v-text="slotProps.inputData.contentTitle"
            class="flexi-wrapper__section--content__block--contentTitle"
          />
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content__block--description"
          />
          <div class="flexi-wrapper__section--content__block--button">
            <flexi-button
              @button-clicked="
                startQuizz(inputData.internalPages[2].sections[8].string_id)
              "
              :text="slotProps.inputData.buttonText"
            />
          </div>
        </div>
        <flexi-quiz
          v-else
          :inputData="inputData.quizzes[4]"
          :contentAos="false"
          :changeSlideOnHeader="true"
          :precent="precentQuizz"
          :resultOptions="{
            limit1: 3,
            limit2: 5
          }"
          :language="language"
        >
          <template v-slot:header="slotProps">
            <div class="flexi-quiz__header--content">
              <span v-text="slotProps.computedIndex" />
              <span v-text="'/'" />
              <span v-text="slotProps.inputData.questions.length" />
            </div>
          </template>
        </flexi-quiz>
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[2]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div id="video5" class="video__wrapper" @click="togglePoster('video5')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/Yik_1634752320_video%203.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[6]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[10].footerHeader" />
              <a
                v-text="slotProps.inputData.widgets[10].footerText"
                :href="slotProps.inputData.widgets[10].buttonLink"
              />
              <a
                v-text="slotProps.inputData.widgets[10].email"
                :href="slotProps.inputData.widgets[10].buttonText"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[2].sections[9]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[2]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderSecondUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiQuiz from "../../../components/FlexiQuiz.vue";

export default {
  name: "part-a",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 2. Видови медиуми",
      meta: [
        {
          name: "description",
          content:
            "Кои се традиционалните медиуми и кој ги поседува? Дознајте зошто треба да знаете кој ги поседува медиумите што ги гледате, читате и слушате."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 2. Видови медиуми"
        },
        {
          name: "og:description",
          content:
            "Кои се традиционалните медиуми и кој ги поседува? Дознајте зошто треба да знаете кој ги поседува медиумите што ги гледате, читате и слушате."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/HtJ_1638412515_u2A.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 2. Видови медиуми"
        },
        {
          name: "twitter:description",
          content:
            "Кои се традиционалните медиуми и кој ги поседува? Дознајте зошто треба да знаете кој ги поседува медиумите што ги гледате, читате и слушате."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/HtJ_1638412515_u2A.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiScrollingSlider,
    FlexiQuiz
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      itemKey: Number,
      openContent: false,
      precentQuizz: [
        {
          x: "96%",
          y: "4%"
        },
        {
          x: "97%",
          y: "3%"
        },
        {
          x: "85%",
          y: "15%"
        },
        {
          x: "81%",
          y: "19%"
        },
        {
          x: "85%",
          y: "15%"
        },
        {
          x: "96%",
          y: "4%"
        },
        {
          x: "87%",
          y: "13%"
        },
        {
          x: "80%",
          y: "20%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "88%",
          y: "12%"
        }
      ],
      showPopUpFirstQuestion: false,
      showQuizz: false,
      subHeaderKey: 0,
      subHeaderSecondUnit: subHeaderSecondUnit
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit2PartA) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#private-non-private-media";
      this.$router.push("/unit-2/part-a");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit2PartA) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    selectActiveClass(key) {
      this.itemKey = key;
    },
    startQuizz(id) {
      this.smoothScroll(id);
      this.showQuizz = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].secondUnitPartA[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit2PartA
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit2PartA");
      this.setLastUnit("/unit-2/part-b");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
