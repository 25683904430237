var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"flexi-header__wrapper"},[_c('div',{staticClass:"flexi-header__wrapper--logo"},[_vm._t("logo",function(){return [_c('img',{attrs:{"src":"https://app.imgforce.com/images/user/zrC_1622176244_logo-black-120.png"},on:{"click":function($event){return _vm.navigateToRoute('/')}}})]})],2),_c('div',{staticClass:"flexi-header__wrapper--navigation"},[_vm._t("navigation",function(){return [_c('flexi-desktop-navigation',{attrs:{"navigation":_vm.inputData,"navigateToExternalPage":_vm.navigateToExternalPage,"navigateToRoute":_vm.navigateToRoute}})]},null,{
        inputData: _vm.inputData,
        toggleTheme: _vm.toggleTheme,
        changeLanguage: _vm.changeLanguage,
        toggleBurger: _vm.toggleBurger,
        navigateToExternalPage: _vm.navigateToExternalPage,
        navigateToRoute: _vm.navigateToRoute,
        isNavOpen: _vm.isNavOpen
      }),_c('flexi-theme-switcher',{attrs:{"toggleTheme":_vm.toggleTheme}}),_c('div',{staticClass:"flexi-language-switcher__wrapper"},[_c('a',{staticClass:"flexi-language-switcher__wrapper--item",attrs:{"href":"https://verified-education.org"}},[_c('span',{staticClass:"item",domProps:{"textContent":_vm._s('МК')}})]),_c('a',{staticClass:"flexi-language-switcher__wrapper--item",attrs:{"href":"https://verified-albania.org","target":"_blank"}},[_c('span',{staticClass:"item",domProps:{"textContent":_vm._s('AL')}})])]),_vm._t("mobile",function(){return [_c('flexi-mobile-navigation',{attrs:{"navigation":_vm.inputData,"isNavOpen":_vm.isNavOpen,"toggleBurger":_vm.toggleBurger,"navigateToExternalPage":_vm.navigateToExternalPage,"navigateToRoute":_vm.navigateToRoute}})]},null,{
        inputData: _vm.inputData,
        toggleTheme: _vm.toggleTheme,
        changeLanguage: _vm.changeLanguage,
        toggleBurger: _vm.toggleBurger,
        navigateToExternalPage: _vm.navigateToExternalPage,
        navigateToRoute: _vm.navigateToRoute,
        isNavOpen: _vm.isNavOpen
      }),_vm._t("user")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }