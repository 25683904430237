var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flexi-quiz",attrs:{"id":"flexi-quizz"}},[_c('div',{staticClass:"flexi-quiz__header"},[_vm._t("header",function(){return _vm._l((_vm.inputData.questions),function(value,key){return _c('div',{key:key,staticClass:"flexi-quiz__header--item",attrs:{"data-aos":_vm.contentAos ? _vm.contentAosType : '',"data-aos-duration":_vm.contentAosDuration},on:{"click":function($event){return _vm.changeSlide(key)}}},[_c('div',{staticClass:"square",class:{
            'active-square': _vm.currentIndex === key,
            'passed-square': _vm.answerCollection.length > key
          }},[(_vm.turnedCalculation)?_c('div',[(!_vm.showResult)?_c('div',[(_vm.answerCollection.length < key + 1)?_c('span',{staticClass:"number",domProps:{"textContent":_vm._s(key + 1)}}):_c('span',{staticClass:"checked"},[_vm._v(" ? ")])]):_c('div',[(!_vm.answerCollection[key])?_c('span',{staticClass:"checked"},[_vm._v(" ✖ ")]):_c('span',{staticClass:"checked"},[_vm._v(" ✓ ")])])]):_c('div',[_c('div',[(!_vm.answerCollection[key])?_c('span',{staticClass:"checked"},[_vm._v(" ✖ ")]):_c('span',{staticClass:"checked"},[_vm._v(" ✓ ")])])])])])})},null,{ computedIndex: _vm.computedIndex, inputData: _vm.inputData, showResult: _vm.showResult })],2),_vm._l(([_vm.currentIndex]),function(key){return _c('div',{key:key,staticClass:"flexi-quiz__content",class:{ 'content-warning': _vm.showWarning }},[(!_vm.showResult)?_c('div',[_c('div',{staticClass:"flexi-quiz__content--question"},[_c('span',{staticClass:"question",attrs:{"data-aos":_vm.contentAos ? _vm.contentAosType : '',"data-aos-duration":_vm.contentAosDuration},domProps:{"textContent":_vm._s(_vm.currentItem.question)}}),_c('img',{staticClass:"image",attrs:{"src":_vm.currentItem.img
              ? _vm.currentItem.img
              : 'https://app.imgforce.com/images/user/gEu_1628691934_350x150.png'}})]),_vm._l((_vm.currentItem.answers),function(value,key){return _c('div',{key:key,staticClass:"flexi-quiz__content--answer",attrs:{"data-aos":_vm.contentAos ? _vm.contentAosType : '',"data-aos-duration":_vm.contentAosDuration}},[(_vm.turnedCalculation)?_c('div',{staticClass:"answer",class:{
            'wrong-answer':
              _vm.selectedAnswer && !value.correct && !_vm.checkCorrectAnswer(),
            'correct-answer': _vm.selectedAnswer && value.correct,
            'selected-answer': _vm.selectedAnswer && value.id === _vm.selectedAnswerId
          },on:{"click":function($event){return _vm.selectAnswer(value, value.id, value.correct)}}},[_c('div',{staticClass:"circle"}),_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(value.answer)}})]):_vm._e(),(_vm.turnedCalculation && _vm.selectedAnswer)?_c('div',{staticClass:"answer icons",class:{
            'wrong-answer':
              _vm.selectedAnswer && !value.correct && !_vm.checkCorrectAnswer(),
            'correct-answer': _vm.selectedAnswer && value.correct,
            'selected-answer': _vm.selectedAnswer && value.id === _vm.selectedAnswerId
          },on:{"click":function($event){return _vm.selectAnswer(value, value.id, value.correct)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"user","size":"1x"}}),(value.correct)?_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.precent[_vm.currentIndex].x)}}):_vm._e(),(!value.correct)?_c('span',{staticClass:"text",domProps:{"textContent":_vm._s(_vm.precent[_vm.currentIndex].y)}}):_vm._e()],1):_vm._e(),(_vm.currentItem.suggestion && value.id === _vm.selectedAnswerId)?_c('span',{staticClass:"suggestion",class:{
            'wrong-suggestion':
              _vm.selectedAnswer && !value.correct && !_vm.checkCorrectAnswer(),
            'correct-suggestion': _vm.selectedAnswer && value.correct
          },domProps:{"innerHTML":_vm._s(_vm.currentItem.suggestion)}}):_vm._e()])}),_c('transition',{attrs:{"name":_vm.transitionWarning,"mode":"out-in"}},[(_vm.showWarning)?_c('div',{key:"content1",staticClass:"flexi-quiz__content--warning"},[_c('span',{staticClass:"warning",domProps:{"textContent":_vm._s(
              _vm.language === 'MK'
                ? '*Изберете еден од понудените одговори'
                : '*Zgjidhni një nga përgjigjet e ofruara'
            )}})]):_vm._e()])],2):_c('div',{attrs:{"data-aos":_vm.contentAos ? _vm.contentAosType : '',"data-aos-duration":_vm.contentAosDuration}},[_c('div',{staticClass:"flexi-quiz__content--result"},[(_vm.correctAnswersType === 'normal')?_c('span',{staticClass:"correct-answers",domProps:{"textContent":_vm._s(
            _vm.language == 'MK'
              ? 'Имате ' + _vm.correctAnswers + ' точни одговори'
              : _vm.correctAnswers + '/' + _vm.inputData.questions.length
          )}}):_c('span',{staticClass:"correct-answers"},[(_vm.language === 'MK')?_c('span',{domProps:{"textContent":_vm._s(
              'Вие одговоривте ' +
                _vm.correctAnswers +
                ' прашања точно од вкупно ' +
                _vm.answerCollection.length +
                ' прашања на темата.'
            )}}):_c('span',{domProps:{"textContent":_vm._s(
              'Ти одговараш ' +
                _vm.correctAnswers +
                ' прашања точно од вкупниот број ' +
                _vm.answerCollection.length +
                ' прашања на темата.'
            )}})]),_c('span',{staticClass:"result",domProps:{"innerHTML":_vm._s(_vm.computedResult)}})])])])}),_c('flexi-pagination',{attrs:{"currentIndex":_vm.currentIndex,"widgets":_vm.inputData.questions},scopedSlots:_vm._u([{key:"next-button",fn:function(slotProps){return [(!_vm.showResult)?_c('a',{staticClass:"flexi-pagination__next",on:{"click":_vm.next}},[_c('div',{staticClass:"pagination-button pagination-button--rotated"},[_c('div',{staticClass:"pagination-button__svg",class:{
              disabled: slotProps.disabledNext,
              blink: _vm.selectedAnswer
            }})])]):_c('a',{staticClass:"flexi-pagination__next restart",on:{"click":_vm.restart}},[_c('div',{staticClass:"pagination-button pagination-button--rotated"},[_c('div',{staticClass:"pagination-button__svg"})])])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }