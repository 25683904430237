<template>
  <div
    class="flexi-container page__internal page__unit-3-part-a"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderThirdUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-3')"
              class="sub-header__pagination--item"
              v-text="'Назад'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Следно'"
              @click="goToRoute('/unit-3/part-b')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].thirdUnitPartA"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Одете нагоре'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section - Социјалните медиуми како извор на вести -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[0]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div
          id="video1"
          class="video__wrapper"
          @click="togglePoster('video1')"
          :data-aos="!isMobile ? 'flip-up' : ''"
          :data-aos-duration="!isMobile ? 1500 : ''"
        >
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/98q_1634752320_video%205.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!--Second Section Персонализирани алгоритми и филтрирани содржини-->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[2]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p
            v-html="slotProps.inputData.description"
            data-aos="fade-up"
            :data-aos-duration="!isMobile ? 1500 : 1000"
          />
        </div>
        <div id="video2" class="video__wrapper" @click="togglePoster('video2')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/KO9_1634752320_video%206.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Quizz Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[3]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:content="slotProps">
        <div v-if="!showQuizz" class="flexi-wrapper__section--content__block">
          <div class="flexi-wrapper__section--content__block--subTitle">
            <h3 v-text="slotProps.inputData.subTitle" />
          </div>
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content__block--img"
          />
          <div
            v-text="slotProps.inputData.contentTitle"
            class="flexi-wrapper__section--content__block--contentTitle"
          />
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content__block--description"
          />
          <div class="flexi-wrapper__section--content__block--button">
            <flexi-button
              @button-clicked="
                startQuizz(inputData.internalPages[5].sections[3].string_id)
              "
              :text="slotProps.inputData.buttonText"
            />
          </div>
        </div>
        <flexi-quiz
          v-else
          :inputData="inputData.quizzes[1]"
          :contentAos="false"
          :changeSlideOnHeader="true"
          :precent="precentQuizz"
          :resultOptions="{
            limit1: 3,
            limit2: 5
          }"
          :language="language"
        >
          <template v-slot:header="slotProps">
            <div
              v-if="!slotProps.showResult"
              class="flexi-quiz__header--content"
            >
              <span v-text="slotProps.computedIndex" />
              <span v-text="'/'" />
              <span v-text="slotProps.inputData.questions.length" />
            </div>
            <div v-else />
          </template>
        </flexi-quiz>
      </template>
    </flexi-wrapper>

    <!-- Fourth Section - ТЕД говор -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[4]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--content-title"
          v-text="slotProps.inputData.contentTitle"
        />
        <div id="video3" class="video__wrapper" @click="togglePoster('video3')">
          <vue-plyr v-if="slotProps.inputData.contentLine">
            <div
              data-plyr-provider="youtube"
              :data-plyr-embed-id="slotProps.inputData.contentLine"
              class="flexi-wrapper__section--content--plyr"
            />
          </vue-plyr>
        </div>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[5]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[10].footerText" />
              <a
                v-text="slotProps.inputData.widgets[10].buttonLink"
                :href="slotProps.inputData.widgets[10].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[5].sections[6]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[4]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderThirdUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiQuiz from "../../../components/FlexiQuiz.vue";

export default {
  name: "part-a",
  metaInfo() {
    return {
      title: "Добро Проверено - Поглавје 3. Социјални медиуми",
      meta: [
        {
          name: "description",
          content:
            "Може ли социјалните медиуми да бидат сигурен извор на вести? Дознајте како функционираат алгоритмите на социјалните медиуми и како тие може да нè изолираат од другите  различни гледишта."
        },
        {
          name: "og:title",
          content: "Добро Проверено - Поглавје 3. Социјални медиуми"
        },
        {
          name: "og:description",
          content:
            "Може ли социјалните медиуми да бидат сигурен извор на вести? Дознајте како функционираат алгоритмите на социјалните медиуми и како тие може да нè изолираат од другите  различни гледишта."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: "https://app.imgforce.com/images/user/Dpa_1638412709_u3a.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "Добро Проверено - Поглавје 3. Социјални медиуми"
        },
        {
          name: "twitter:description",
          content:
            "Може ли социјалните медиуми да бидат сигурен извор на вести? Дознајте како функционираат алгоритмите на социјалните медиуми и како тие може да нè изолираат од другите  различни гледишта."
        },
        {
          name: "twitter:image",
          content: "https://app.imgforce.com/images/user/Dpa_1638412709_u3a.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiScrollingSlider,
    FlexiQuiz
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      openContent: false,
      precentQuizz: [
        {
          x: "95%",
          y: "5%"
        },
        {
          x: "89%",
          y: "11%"
        },
        {
          x: "84%",
          y: "16%"
        },
        {
          x: "79%",
          y: "21%"
        },
        {
          x: "85%",
          y: "15%"
        },
        {
          x: "96%",
          y: "4%"
        },
        {
          x: "88%",
          y: "12%"
        },
        {
          x: "79%",
          y: "21%"
        },
        {
          x: "78%",
          y: "22%"
        },
        {
          x: "87%",
          y: "13%"
        }
      ],
      showPopUpFirstQuestion: false,
      showQuizz: false,
      subHeaderKey: 0,
      subHeaderThirdUnit: subHeaderThirdUnit
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit3PartA) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#social-media-as-a-newssource";
      this.$router.push("/unit-3/part-a");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit3PartA) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    startQuizz(id) {
      this.smoothScroll(id);
      this.showQuizz = true;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].thirdUnitPartA[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit3PartA
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit3PartA");
      this.setLastUnit("/unit-3/part-b");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
